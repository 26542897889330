import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import "./news-single.scss"

const ArticlePage = props => {
  const page = props.data.page

  return (
    <Layout>
      <SEO title={page.title + " | USLPA"} />
      <div className="container">
        <h1>{page.title}</h1>
      </div>
      {page.article.map(
        (block, index) => getContentBlock(block)[block.type]
      )}
    </Layout>
  )
}

export default ArticlePage

const getContentBlock = props => {
  return {
    paragraph: <ArbitraryContent content={props.content} key={props.index} />,
    "image-half": (
      <ImageHalf
        content={props.content}
        image={props.image}
        key={props.index}
      />
    ),
  }
}

const ArbitraryContent = ({ content, index }) => {
  return (
    <section className="arbitrary-content-section" key={index}>
      <div className="container">
        <div
          className="text-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </section>
  )
}

const ImageHalf = ({ content, image, index }) => (
  <section className="image-half-section" key={index}>
    <div className="container">
      <div className="image-half-wrapper">
        <div className="image-container">
          <Img
            fluid={image.src.childImageSharp.fluid}
            className="image-half"
            alt={image.alt_text}
          />
        </div>
        <div
          className="text-half"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  </section>
)

export const pageQuery = graphql`
  query($id: String!) {
    page: newsJson(id: { eq: $id }) {
      title
      article {
        type
        image {
          alt_text
          src {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        content
      }
    }
  }
`
